import { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { CabButton } from "@CabComponents/CabButton";
import { CabControlLabel } from "@CabComponents/CabControlLabel";
import { CabDurationPicker } from "@CabComponents/CabDurationPicker";
import { CabSwitch } from "@CabComponents/CabSwitch";
import { CabModal } from "@CabComponents/CabModal";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { Controller, useForm } from "react-hook-form";
import { Meeting } from "../../../store";
import { 
  CabTextTokenInput, transformMarkupToSimpleTemplate, transformMarkupToText, transformSimpleTemplateToMarkup 
} from "@CabComponents/CabTextTokenInput";
import { getTemplateVars } from "../../../utils/scheduleUtils";
import colors from "../../../colors";
import { CabTooltip } from "@CabComponents/CabTooltip";
import { CabIcon } from "@CabComponents/CabIcon";
import { IoHelpCircleOutline } from 'react-icons/io5';

export type DuplicateMeetingSubmitProps = Partial<Meeting> & {copyParticipants: boolean};

export type DuplicateMeetingModalProps = {
  open: boolean
  onClose: () => void
  meeting: Omit<Partial<Meeting>, "status"> | undefined
  submitDuplicate: (data: DuplicateMeetingSubmitProps) => Promise<Meeting | undefined>
};

const DuplicateMeetingModal = (
  { open, onClose, meeting, submitDuplicate }: DuplicateMeetingModalProps
): React.ReactElement => {
  const showTokenInputs = !meeting?.is_poll;

  const templateVars = useMemo(() => {
    return getTemplateVars(meeting?.questions);
    // needs deep comparison
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(meeting?.questions)]);

  const templateNameLookup = useMemo(() => Object.entries(templateVars)
    .map(([k, v]) => ({ [k]: v.replaceText }))
    .reduce((a, b) => ({ ...a, ...b }), {})
  , [templateVars]);

  const [disableButtonsOnSubmit, setDisableButtonsOnSubmit] = useState(false);

  
  const defaults = useMemo(() => {
    const meetingTitle = meeting?.title ? "[Copy] " + meeting?.title : "";
    const meetingDescription = meeting?.description || "";

    return {
      title: showTokenInputs
        ? transformSimpleTemplateToMarkup(meetingTitle, templateNameLookup)
        : meetingTitle,
      description: showTokenInputs
        ? transformSimpleTemplateToMarkup(meetingDescription, templateNameLookup)
        : meetingDescription,
      duration_minutes: meeting?.duration_minutes || 30,
      num_expected_participants: meeting?.num_expected_participants || 1,
      copyParticipants: false,
      internal_label: meeting?.internal_label || transformMarkupToText(meetingTitle)
    };
  }, [meeting?.title, meeting?.description, meeting?.duration_minutes, meeting?.num_expected_participants,
    meeting?.internal_label, showTokenInputs, templateNameLookup]);

  const { control, handleSubmit, reset } = useForm({ defaultValues: defaults });

  useEffect(() => {
    reset(defaults);
  }, [defaults, reset]);

  const handleSubmitDuplicateMeeting = async (data: DuplicateMeetingSubmitProps) => {
    const meetingData = { ...data };
    if (meetingData.title) meetingData.title = transformMarkupToSimpleTemplate(meetingData.title);
    if (!meetingData.internal_label) meetingData.internal_label = transformMarkupToText(defaults.title);
    if (meetingData.description) meetingData.description = transformMarkupToSimpleTemplate(meetingData.description);
    if (!meeting?.is_reusable) meetingData.internal_label = undefined;

    setDisableButtonsOnSubmit(true);
    await submitDuplicate(meetingData);
    setDisableButtonsOnSubmit(false);
  };

  return <>
    {meeting ? <CabModal
      open={open}
      onClose={onClose}
      title={!meeting.is_poll ? "Duplicate Meeting" : "Duplicate Poll"}
      actionButtons={
        <>
          <CabButton
            buttonType="tertiary"
            onClick={() => onClose()}
            disabled={disableButtonsOnSubmit}
          >
            Cancel
          </CabButton>
          <CabButton
            onClick={handleSubmit((data) => handleSubmitDuplicateMeeting(data))}
            disabled={disableButtonsOnSubmit}
          >
            {!meeting.is_poll ? "Create Meeting" : "Create Poll"}
          </CabButton>
        </>
      }
    >
      <form>
        {meeting?.is_reusable &&
          <CabControlLabel
            label="Internal Meeting Label"
            labelPlacement="top"
            sx={{
              width: "100%",
              alignItems: "inherit",
              marginBottom: 1
            }}
            control={<Controller
              name="internal_label"
              control={control}
              render={({
                field: { ref, ...field },
              }) => (
                <CabTextInput
                  {...field}
                  inputRef={ref}
                  placeholder={transformMarkupToText(defaults.title || '')}
                  multiline
                  minRows={1}
                  maxRows={3}
                  formControlSx={{ flex: 1 }}
                  endIcon={
                    <CabTooltip
                      wrapWithSpan
                      title={`This label helps you organize your reusable meetings. It will not be on
                        any events, and your attendees will never see it.`}
                      sx={{alignSelf: 'center'}}
                    >
                      <CabIcon Icon={IoHelpCircleOutline} sx={{ fontSize: '20px' }} />
                    </CabTooltip>
                  }
                />
              )}
            />}
          />
        }
        <CabControlLabel
          label="Title"
          labelPlacement="top"
          sx={{
            width: "100%",
            alignItems: "inherit",
            marginBottom: 1
          }}
          control={<Controller
            name="title"
            control={control}
            render={({
              field: { ref, ...field },
            }) => (
              showTokenInputs ? (
                <CabTextTokenInput
                  {...field}
                  inputRef={ref}
                  required
                  multiline
                  templateVars={templateVars}
                  inputProps={{ style: { maxHeight: 75 } }}
                  tokenColor={colors.black400}
                />
              ) : (
                <CabTextInput inputRef={ref} {...field} />
              )
            )}
          />}
        />
        <CabControlLabel
          label="Description"
          labelPlacement="top"
          sx={{
            width: "100%",
            alignItems: "inherit",
            marginBottom: 1
          }}
          control={<Controller
            name="description"
            control={control}

            render={({
              field: { ref, ...field },
            }) => (
              showTokenInputs ? (
                <CabTextTokenInput
                  {...field}
                  inputRef={ref}
                  multiline
                  templateVars={templateVars}
                  inputProps={{ style: { height: 75 } }}
                  tokenColor={colors.black400}
                />
              ) : (
                <CabTextInput
                  multiline
                  maxRows={6}
                  minRows={3}
                  inputRef={ref}
                  sx={{
                    width: "100%"
                  }}
                  {...field}
                />
              )
            )}
          />}
        />
        <Box display={"flex"} justifyContent={meeting?.is_poll ? "space-between" : "flex-start"}>
          <CabControlLabel
            label="Duration"
            labelPlacement="top"
            sx={{
              width: "30%",
              alignItems: "inherit",
              marginBottom: 1,
              "& .MuiFormControlLabel-label": {
                marginBottom: 1,
              }
            }}
            control={<Controller
              name="duration_minutes"
              control={control}
              render={({
                field: { value, ref, ...props },
              }) => (
                <CabDurationPicker durationMinutes={value || 0} {...props} />
              )}
            />}
          />
          {meeting?.is_poll &&
            <>
              <CabControlLabel
                label="# Attendees"
                labelPlacement="top"
                sx={{
                  width: "25%",
                  alignItems: "inherit",
                  marginBottom: 1,
                  "& .MuiFormControlLabel-label": {
                    marginBottom: 1,
                  }
                }}
                control={<Controller
                  name="num_expected_participants"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <CabTextInput
                      {...field}
                      inputRef={ref}
                      required
                      size={"xsmall"}
                      inputProps={{
                        min: meeting?.leaders?.length || 0,
                        type: 'number',
                      }}
                    />
                  )} />}
              />
              <CabControlLabel
                label="Copy Attendees"
                labelPlacement="top"
                sx={{
                  width: "30%",
                  alignItems: "inherit",
                  marginBottom: 1,
                  "& .MuiFormControlLabel-label": {
                    marginBottom: 1,
                  }
                }}
                control={<Controller
                  name="copyParticipants"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <CabSwitch
                      {...field}
                    />
                  )} />}
              />
            </>
          }
        </Box>
      </form>
    </CabModal>
      :
      <></>
    }
  </>;
};

export default DuplicateMeetingModal;