import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { Box, BoxProps } from "@mui/material";
import { IoCopyOutline } from 'react-icons/io5';
import { useCallback, useState } from "react";
import { Clipboard } from '@capacitor/clipboard';
import colors from "../../../colors";

export type CopyLinkProps = {
  link: string
  sx?: BoxProps["sx"]
  callback?: () =>  void
};

export const CopyLink = ({link, sx, callback}: CopyLinkProps) => {
  const [copyAlert, setCopyAlert] = useState(false);
  
  const copyLink = useCallback(() => {
    Clipboard.write({
      string: link
    }).then(() => {
      setCopyAlert(true);
      setTimeout((): void => {
        setCopyAlert(false);
      }, 750);
    });
    if (callback) {
      callback();
    }
  }, [callback, link]);

  return <Box position="relative" sx={{...sx, "& .MuiFormControl-root": {width: "100%!important"}}}>
    <CabTextInput value={link} sx={{
      backgroundColor: colors.black100,
      "& .MuiInputBase-input": {paddingRight: 13},
      width: "100%!important",
      borderRadius: 1
    }} />
    <CabButton
      icon={<CabIcon
        Icon={IoCopyOutline}
      />}
      onClick={copyLink}
      sx={{
        position: "absolute",
        right: 0,
        top:0,
        height: "100%",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        width: "100px"
      }}>
      {copyAlert ?
        <>Copied</>
        :
        <>Copy</>
      }
    </CabButton>
  </Box>;
};

export default CopyLink;