import { FormControl, Box, Typography, styled, Skeleton, ButtonBase, Alert } from "@mui/material";
import React, { ReactElement } from "react";
import { CabButton, CabIcon, CabTimezoneInput } from "@CabComponents";
import colors from "../../../colors";
import chroma from "chroma-js";
import CabSpinner from "@CabComponents/CabSpinner";
import { IoLockClosedOutline, IoAdd, IoCloseCircle, IoCopyOutline, IoInformationCircle } from "react-icons/io5";


const TextBox = styled(Box, { label: 'TextBox' })(() => ({
  border: '1px solid',
  borderColor: colors.black200,
  borderRadius: 4,
  width: '100%',
  padding: '8.5px 14px 39px 14px',
  marginTop: 16,
  height: '100%',
  overflowY: 'auto',
  boxSizing: 'border-box',
  direction: 'rtl',
  '::-webkit-scrollbar': {
    width: 4,
    height: 8,
    backgroundColor: 'none',
  },
  '::-webkit-scrollbar-thumb': {
    background: colors.black500,
    borderRadius: 2,
  }
}));

export interface ShareTimesProps {
  hyperlinkedSlots: string;
  formattedSlots: string;
  templateTimezone: string | null;
  onTemplateTimezoneSelect: (timezone: string) => void;
  additionalCopyTimezones: string[];
  onAddAdditionalCopyTimezone: () => void;
  onRemoveAdditionalCopyTimezone: (idx: number) => void;
  onChangeAdditionalCopyTimezone: (value: string | null, idx: number) => void;
  loadingAdditionalTimezone: boolean;
  loadingTimes: boolean;
  emptySlotMessage: string;
  infoMessage: string;
  hasMultiTimezone?: boolean;
  openUpgradeModal: () => void;
  showTimesWithLinks: boolean;
  copyAlert: boolean;
  copyTimes: () => void;
  openUnavailableSlotsModal: () => void;
  isSmDown: boolean;
}

const ShareTimes = ({
  hyperlinkedSlots, formattedSlots, templateTimezone, onTemplateTimezoneSelect, additionalCopyTimezones, 
  loadingAdditionalTimezone, onAddAdditionalCopyTimezone, onRemoveAdditionalCopyTimezone, 
  onChangeAdditionalCopyTimezone, loadingTimes, emptySlotMessage, infoMessage, hasMultiTimezone, openUpgradeModal, 
  showTimesWithLinks, copyAlert, copyTimes, openUnavailableSlotsModal, isSmDown
}: ShareTimesProps): ReactElement => {
  const handleTimezoneChange = (value: string | null) => {
    if (value != null) {
      onTemplateTimezoneSelect(value);
    }
  };
  
  return (
    <Box display='flex' flexDirection='column' height={'100%'} overflow={'hidden'}>
      <Typography variant="h2"> Share Text </Typography>
      <Box display='flex' justifyContent='space-between'>
        <Box display='flex' alignItems='center' gap={2} marginTop={1}>
          <Typography>Timezones</Typography>
          <Box display='flex' alignItems='center' onClick={!hasMultiTimezone ? openUpgradeModal : undefined}
            sx={{cursor: 'pointer'}}>
            {!hasMultiTimezone  && (
              <CabIcon
                Icon={IoLockClosedOutline}
                alt='Locked'
                slot='end'
                sx={{ fontSize: 20 }}
              />
            )}
            <CabButton
              buttonType='text'
              color="primary"
              onClick={onAddAdditionalCopyTimezone}
              disabled={loadingAdditionalTimezone || !hasMultiTimezone}
              sx={{height: 30}}
              icon={<CabIcon Icon={IoAdd} alt='Add' />}
            >
              Add Timezone
            </CabButton>
          </Box>
        </Box>
      </Box>
      <Box display='flex' flexWrap='wrap' paddingTop={1} rowGap={1} columnGap={1.75} width={'100%'}
        justifyContent={additionalCopyTimezones && additionalCopyTimezones.length > 1 ? 'normal' : 'space-between'}>
        <CabTimezoneInput
          label='Timezone'
          shortFormat={additionalCopyTimezones && additionalCopyTimezones.length > 1}
          value={templateTimezone ? templateTimezone : ''}
          required={true}
          size='small'
          defaultValue={templateTimezone ? templateTimezone : ''}
          onChange={handleTimezoneChange}
          sx={{minWidth: additionalCopyTimezones && additionalCopyTimezones.length > 1 ? '17%' : '48%'}}
        />
        {additionalCopyTimezones?.length > 0 && (
          additionalCopyTimezones.map((timezone, index) => {
            return (
              <Box key={index} sx={{minWidth: additionalCopyTimezones?.length > 1 ? '17%' : '48%'}}>
                <Box display='flex'>
                  <FormControl fullWidth>
                    <CabTimezoneInput
                      label='Timezone'
                      value={timezone ?? ''}
                      size='small'
                      shortFormat={additionalCopyTimezones?.length > 1}
                      required={true}
                      defaultValue={timezone ?? ''}
                      onChange={(v) => onChangeAdditionalCopyTimezone(v, index)}
                    />
                    <ButtonBase
                      onClick={() => onRemoveAdditionalCopyTimezone(index)}
                      title='Remove'
                      disabled={loadingAdditionalTimezone}
                      sx={{
                        position: 'absolute',
                        left: -10,
                        top: -8,
                        color: colors.coralLight,
                        padding: 0,
                        backgroundColor: 'transparent',
                        fontSize: 20,
                        boxShadow: 'none',
                        borderRadius: '50%',
                        zIndex: 10
                      }}
                    >
                      <Box component="span" sx={{
                        backgroundColor: colors.navyDark,
                        position: 'absolute',
                        top: 5,
                        left: 5,
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                      }}></Box>
                      <CabIcon
                        Icon={IoCloseCircle}
                        sx={{ padding: '-4px', pointerEvents: 'none', zIndex: 1 }}
                      />
                    </ButtonBase>
                  </FormControl>
                </Box>
              </Box>
            );
          })
        )}
        {loadingAdditionalTimezone && 
        <Box paddingTop={0.5}>
          <CabSpinner scale={1} />
        </Box>
        }
      </Box>
      
      <Box width='100%' height={infoMessage ? 'calc(100% - 166px)' : 'calc(100% - 126px)'}>
        {loadingTimes ? (
          <TextBox>
            <Box sx={{direction: 'ltr'}}>
              <Skeleton height={30} width={'40%'} />
              <Skeleton height={30} width={'70%'} />
              <Skeleton height={30} width={'70%'} />
              <Skeleton height={30} width={'70%'} />
              <Skeleton height={30} width={'40%'} />
              <Skeleton height={30} width={'70%'} />
              <Skeleton height={30} width={'70%'} />
              <Skeleton height={30} width={'70%'} />
            </Box>
          </TextBox>
        ) : (
          !emptySlotMessage ? (
            <Box position='relative' sx={{"& .MuiFormControl-root": {width: "100%!important"}}} height={'100%'}>
              <TextBox>
                {showTimesWithLinks ? (
                  <Typography
                    id="copyTimesWithLinksText"
                    fontFamily={'Arial'}
                    color={colors.black900}
                    fontSize={15}
                    dangerouslySetInnerHTML={{__html: hyperlinkedSlots}}
                    sx={{direction: 'ltr'}}
                  />
                ) : (
                  <Typography
                    fontFamily='Arial'
                    color={colors.black900}
                    fontSize={15}
                    sx={{direction: 'ltr'}}
                    whiteSpace='pre-wrap'
                  >
                    {formattedSlots}
                  </Typography>
                )}
              </TextBox>
              <CabButton
                key={showTimesWithLinks ? "copyTimesLinked" : "copyTimes"}
                id={showTimesWithLinks ? "copyTimesWithLinksButton" : ""}
                onClick={!showTimesWithLinks ? copyTimes : undefined}
                HTML5DataAttributes={showTimesWithLinks ? 
                  {'data-clipboard-target': "#copyTimesWithLinksText"} : undefined}
                icon={<CabIcon
                  Icon={IoCopyOutline}
                />}
                sx={{
                  position: "absolute", right: 0, bottom:0, borderTopRightRadius: 0, borderBottomLeftRadius: 0,
                  width: "100px"}}>
                {copyAlert ? <>Copied</> : <>Copy</> }
              </CabButton>
            </Box>
          ) : (
            <Box sx={{
              border: '1px solid',
              borderColor: colors.black200,
              borderRadius: 1,
              padding: '8.5px 14px 39px 14px',
              marginTop: 2,
              height: '100%'
            }}>
              <Alert severity="warning" variant="outlined">
                {emptySlotMessage}
              </Alert>
            </Box>
          ))}
        {infoMessage && !loadingTimes && (
          <Box display="flex" marginTop={1} flexDirection="row" alignItems="center" gap={1} borderRadius={1} 
            padding={0.75} sx={{ backgroundColor: chroma(colors.blueLight).alpha(0.3).hex() }}
          >
            <CabIcon Icon={IoInformationCircle} sx={{ fontSize: 20 }} />
            <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
              <Typography fontSize={14}>{infoMessage}</Typography>
              <Typography fontSize={14} onClick={openUnavailableSlotsModal} sx={{
                cursor: "pointer",
                color: "#3d5bb3",
                textDecoration: "underline",
                marginRight: "8px"
              }}>{ isSmDown ? 'Why?' : 'Show me why'}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ShareTimes;