import { Box, Typography } from "@mui/material";
import { ReactElement } from "react";
import { CabIcon, CabModal } from "@CabComponents";
import { BookingSlot } from "../../../store";
import { TimeZone, allTimeZones } from "../../../utils/scheduleUtils";
import { DateTime } from "luxon";
import colors from "../../../colors";
import { 
  IoHourglassOutline, IoTimeOutline, IoCalendarNumberOutline, IoCodeWorkingOutline, IoSadOutline, IoBanOutline, 
  IoAlertCircleOutline 
} from "react-icons/io5";


export interface UnavailableTimesModalProps {
  modalOpen: boolean;
  onModalClose: () => void;
  unavailableSlots: BookingSlot[];
  timezone: string | null;
}

const UnavailableTimesModal = ({
  modalOpen, onModalClose, unavailableSlots, timezone
}: UnavailableTimesModalProps): ReactElement => {
  const tz = allTimeZones.find((zone: TimeZone) => zone.name === timezone);
  const timezoneName = tz?.name || '';
  const timezoneAbr = tz?.abbreviation || '';
  const slotsByDay: {[date: string]: BookingSlot[]}  = {};

  unavailableSlots
    .slice().sort((slotA, slotB) => slotA.start.localeCompare(slotB.start))
    .forEach(slot => {
      const date = DateTime.fromISO(slot.start).setZone(timezoneName).toFormat('ccc, MMM dd');
      
      if (slotsByDay[date]) {
        slotsByDay[date].push(slot);
      } else {
        slotsByDay[date] = [slot];
      }
    });
  
  return <>
    {modalOpen && (
      <CabModal
        open={modalOpen}
        onClose={onModalClose}
        closeIcon={true}
        closeOnBackdropClick={true}
        title={"Unavailable Times"}
        stickyBottomContent={<></>}
        noFullScreen={false}
      >
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          {Object.keys(slotsByDay).map((date) =>
            <Box key={date} display={'flex'} flexDirection={'column'} gap={1}> 
              <Typography variant='h2'>{date}</Typography>
              {slotsByDay[date].map((slot) => 
                <UnavailableDateSlotTimeCard
                  key={slot.id}
                  slot={slot}
                  timezoneName={timezoneName}
                  timezoneAbr={timezoneAbr}
                />
              )}
            </Box>
          )}
        </Box>
      </CabModal>
    )}
  </>;
};

interface UnavailableSlotTimeCards {
  slot: BookingSlot;
  timezoneName: string;
  timezoneAbr: string;
}

const UnavailableDateSlotTimeCard = ({slot, timezoneName, timezoneAbr}: UnavailableSlotTimeCards): ReactElement => {
  const timeString = `${DateTime.fromISO(slot.start).setZone(timezoneName).toFormat('h:mm a')
    .toLowerCase()} - ${DateTime.fromISO(slot.end).setZone(timezoneName).toFormat('h:mm a').toLowerCase()} 
    ${timezoneAbr}`;

  const reasonIcon = () => {
    const reason = slot.detail?.reason;

    if (reason === 'PAST') {
      return <CabIcon Icon={IoHourglassOutline} alt='Past'/>;
    } else if (reason === 'NOTICE') {
      return <CabIcon Icon={IoTimeOutline} alt='Notice'/>;
    } else if (reason === 'EVENT') {
      return <CabIcon Icon={IoCalendarNumberOutline} alt='Event'/>;
    } else if (reason === 'BUFFER') {
      return <CabIcon Icon={IoCodeWorkingOutline} alt='Past'/>;
    } else if (reason === 'TAKEN') {
      return <CabIcon Icon={IoSadOutline} alt='Taken'/>;
    } else if (reason === 'EXCLUSION') {
      return <CabIcon Icon={IoBanOutline} alt='Past'/>;
    } else {
      return <CabIcon Icon={IoAlertCircleOutline} alt='Other'/>;
    }
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={.5} border={`1px solid ${colors.black200}`} borderRadius={1}
      padding={1}>
      <Box display='flex' justifyContent={'space-between'}>
        <Typography variant='body1'>{timeString}</Typography>
        {reasonIcon()}
      </Box>
      {slot.detail?.message && (
        <Typography variant='body2'>{`Reason: ${slot.detail.message}`}</Typography>
      )}
      {slot.detail?.extra?.event_title && (
        <Typography variant='body2'>{`Event: ${slot.detail.extra.event_title}`}</Typography>
      )}
    </Box>
  );
};


export default UnavailableTimesModal;